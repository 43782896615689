import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule, ErrorHandler, APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { TiimeAuthService } from 'tiime-auth';

import { GlobalErrorHandler } from '@modules/core/global-error-handler';
import { SentryService } from '@modules/core/sentry/sentry.service';
import { SharedModule } from '@shared/shared.module';
import { UserEffects } from '@store/user/user-effect';
import * as userReducer from '@store/user/user-reducer';

import { ApiInterceptor } from './api.interceptor';
import { AuthModule } from './auth/auth.module';
import { NotFoundComponent } from './not-found/not-found.component';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';

export function initializeSentry(sentryService: SentryService): () => void {
  return () => sentryService.init();
}

export function refreshAuthToken(tiimeAuthService: TiimeAuthService): () => void {
  return () => tiimeAuthService.scheduleRenewal();
}

@NgModule({
  imports: [
    AuthModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    SharedModule,
    StoreModule.forRoot({ user: userReducer.reducer }),
    EffectsModule.forRoot([UserEffects]),
  ],
  declarations: [NotFoundComponent, UnauthorizedComponent],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initializeSentry,
      deps: [SentryService],
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: refreshAuthToken,
      deps: [TiimeAuthService],
      multi: true
    },
    { provide: HTTP_INTERCEPTORS, useClass: ApiInterceptor, multi: true },
    { provide: ErrorHandler, useClass: GlobalErrorHandler }

  ],
  exports: [BrowserModule]
})
export class CoreModule {
}
