import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from '@guards/auth.guard';
import { UserAdminGuard } from '@guards/user-admin.guard';
import { UserGuard } from '@guards/user.guard';
import { AuthCallbackComponent } from '@modules/core/auth/auth-callback/auth-callback.component';
import { ChangePasswordComponent } from '@modules/core/auth/change-password/change-password.component';
import { SigninComponent } from '@modules/core/auth/signin/signin.component';
import { NotFoundComponent } from '@modules/core/not-found/not-found.component';
import { UnauthorizedComponent } from '@modules/core/unauthorized/unauthorized.component';
import { AppRoute } from '@navigation/app-route.enum';

const routes: Routes = [
  { path: AppRoute.signin, component: SigninComponent },
  { path: AppRoute.changePassword, component: ChangePasswordComponent },
  { path: AppRoute.authCallback, component: AuthCallbackComponent },
  { path: AppRoute.unauthorized, component: UnauthorizedComponent },
  {
    path: AppRoute.admin,
    loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule),
    canLoad: [AuthGuard, UserGuard, UserAdminGuard]
  },
  { path: AppRoute.notFound, component: NotFoundComponent },
  {
    path: AppRoute.empty,
    loadChildren: () => import('./main/main.module').then(m => m.MainModule),
    canLoad: [AuthGuard, UserGuard]
  },
  { path: AppRoute.others, component: NotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
