import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';

import { AutoSaveStatus } from './auto-save-status.enum';

@Component({
  selector: 'tiime-auto-save-status',
  templateUrl: './auto-save-status.component.html',
  styleUrls: ['./auto-save-status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AutoSaveStatusComponent {
  @Input() autoSaveStatus: AutoSaveStatus;
  @Input() mode: 'icon' | 'text' = 'icon';

  /**
   * @internal
   * @private
   */
  readonly AutoSaveStatus = AutoSaveStatus;

  constructor(private cdr: ChangeDetectorRef) {}

  /**
   * Met à jour le status
   * Si un timeout est fourni (en MS), il fera un reset du status à la fin de sa durée
   */
  updateAutoSaveStatus(autoSaveStatus: AutoSaveStatus, timeout: number = null): void {
    this.autoSaveStatus = autoSaveStatus;
    this.cdr.markForCheck();
    if (timeout > 0) {
      setTimeout(() => {
        this.autoSaveStatus = undefined;
        this.cdr.markForCheck();
      }, timeout);
    }
  }

  beginAutoSave(): void {
    this.updateAutoSaveStatus(AutoSaveStatus.BeginSave);
  }

  autoSaveSuccess(timeout = 2000): void {
    this.updateAutoSaveStatus(AutoSaveStatus.SaveSuccess, timeout);
  }

  autoSaveError(timeout = 200): void {
    this.updateAutoSaveStatus(AutoSaveStatus.SaveError, timeout);
  }
}
