import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';

import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';

import { SnackbarConfig, TiimeSnackbarService } from 'tiime-material';

import { RoleType } from '@enums/role-type.enum';
import { userRolesSelector } from '@store/user/user-selector';

import { UserGuard } from './user.guard';

@Injectable({
  providedIn: 'root'
})
export class UserAdminGuard {
  constructor(
    private store: Store,
    private snackbarService: TiimeSnackbarService,
    private router: Router,
    private userGuard: UserGuard
  ) {}

  canLoad(): Observable<boolean | UrlTree> {
    return this.userGuard.canLoad().pipe(switchMap(() => this.isUserAdmin()));
  }

  private isUserAdmin(): Observable<boolean | UrlTree> {
    return this.store.pipe(
      select(userRolesSelector),
      map((roles: RoleType[]) => {
        if (!roles?.includes(RoleType.DATA_ADMIN)) {
          this.snackbarService.open(`Accès refusé`, SnackbarConfig.error);
          return this.router.createUrlTree(['/signin']);
        }
        return true;
      }),
      take(1)
    );
  }
}
