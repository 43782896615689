<div class="sidenav" [class.closed]="!opened">
  <div
    class="sidenav-toogle"
    (click)="onToogleSidenav()"
    *ngIf="!disableToogle"
  >
    <img src="assets/arrow.svg" />
  </div>
  <ng-content select="[sidenav]"></ng-content>
</div>
<div class="shadow-container" [class.closed]="!opened">
  <div class="rounded-app-container">
    <ng-content></ng-content>
  </div>
</div>
