<app-auth-layout>
  <form class="auth-form" [formGroup]="changePasswordForm" (ngSubmit)="submitChangePasswordForm()">
    <div tiimeForm>
      <div tiimeFormColumn>
        <div class="auth-form-header">
          <div class="auth-form-header-illustration"></div>
          <span class="auth-form-header-title">Modifier mon mot de passe</span>
        </div>
        <div *ngIf="isMailSent; then mailSent; else form"></div>
        <ng-template #form>
          <div class="auth-form-content">
            <span class="info">Je saisis mon adresse mail</span>
            <div class="input-container">
              <input type="text" placeholder="Adresse email" formControlName="email" />
              <label class="input-error-label" *ngIf="changePasswordForm.emailErrorMessage">
                {{ changePasswordForm.emailErrorMessage }}
              </label>
            </div>
            <button tiime-button color="accent" large raised data-cy="forgot-password-btn">Envoyer l'email</button>
          </div>
        </ng-template>
        <ng-template #mailSent>
          <div class="auth-form-content">
            <span class="info">Un email vient d'être envoyé pour réinitialiser le mot de passe</span>
            <button tiime-button color="accent" large raised [routerLink]="signinRouterLink">
              Ré-essayer de me connecter
            </button>
          </div>
        </ng-template>
        <div class="auth-form-link bottom">
          Déjà un compte ?
          <br />
          <a [routerLink]="signinRouterLink">Je me connecte sur Tiime Data !</a>
        </div>
      </div>
    </div>
  </form>
</app-auth-layout>
