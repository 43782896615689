import { RoleType } from '@enums/role-type.enum';

import { UserBase } from './user-base';

export interface UserJson {
  id?: number;
  lastname?: string;
  firstname?: string;
  email?: string;
  roles?: RoleType[];
  acronym?: string;
  color?: string;
}

export class User extends UserBase {
  constructor(
    public id?: number,
    public lastname?: string,
    public firstname?: string,
    public email?: string,
    public roles?: RoleType[],
    public acronym?: string,
    public color?: string
  ) {
    super(id, lastname, firstname, email);
  }

  static fromJson(json: Partial<UserJson>): User {
    return new User(json.id, json.lastname, json.firstname, json.email, json.roles, json.acronym, json.color);
  }

  static toJson(user: User): UserJson {
    return {
      id: user.id,
      lastname: user.lastname,
      firstname: user.firstname,
      email: user.email,
      roles: user.roles
    };
  }
}
