import { Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[tiimeSelectInput]',
  host: {
    class: 'tiime-select-input'
  }
})
export class SelectInputDirective {
  constructor(public elementRef: ElementRef) {}
}
