import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';

export class SignInForm extends UntypedFormGroup {
  get email(): UntypedFormControl {
    return this.get('email') as UntypedFormControl;
  }
  get emailErrorMessage(): string {
    return this.email.touched && this.email.hasError('required')
      ? 'Email requis'
      : null;
  }

  get password(): UntypedFormControl {
    return this.get('password') as UntypedFormControl;
  }
  get passwordErrorMessage(): string {
    return this.password.touched && this.password.hasError('required')
      ? 'Mot de passe requis'
      : null;
  }

  constructor() {
    super({
      email: new UntypedFormControl('', Validators.required),
      password: new UntypedFormControl('', Validators.required)
    });
  }
}
