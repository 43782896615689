export enum MainRoute {
  empty = '',
  companiesInitialization = 'companies-initialization',
  regex = 'regex',
  transactions = 'transactions',
  riskyTransactions = 'risky-transactions',
  balances = 'balances',
  manualOcr = 'manual-ocr',
  duplicateDocuments = 'duplicate-documents',
  receipt = 'receipts/:id',
  matching = 'matching'
}
