import { Directive } from '@angular/core';

@Directive({
  selector: '[tiimeForm]',
  exportAs: 'tiime-form',
  host: { class: 'tiime-form' }
})
export class FormDirective {}

@Directive({
  selector: '[tiimeFormTitle]',
  exportAs: 'tiime-form-title',
  host: {
    class: 'tiime-form-title'
  }
})
export class FormTitleDirective {}

@Directive({
  selector: '[tiimeFormColumn]',
  exportAs: 'tiime-form-column',
  host: {
    class: 'tiime-form-column'
  }
})
export class FormColumnDirective {}

@Directive({
  selector: '[tiimeFormRow]',
  exportAs: 'tiime-form-row',
  host: {
    class: 'tiime-form-row'
  }
})

export class FormRowDirective {}
@Directive({
  selector: '[tiimeFormInputContainer]',
  exportAs: 'tiime-form-input-container',
  host: {
    class: 'tiime-form-input-container'
  }
})
export class FormInputDirective {}

@Directive({
  selector: '[tiimeFormVerticalSeparator]',
  exportAs: 'tiime-form-vertical-separator',
  host: {
    class: 'tiime-form-vertical-separator'
  }
})
export class FormVerticalSeparatorDirective {}
