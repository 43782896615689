import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ContainerComponent } from './container.component';
import { ContainerContentDirective } from './container-content.directive';
import { ContainerFooterDirective } from './container-footer.directive';
import { ContainerHeaderLeftDirective } from './container-header-left.directive';
import { ContainerHeaderRightDirective } from './container-header-right.directive';
import { ContainerHeaderDirective } from './container-header.directive';
import { ContainerOnboardDirective } from './container-onboard.directive';
import { ContainerContentPlaceholderDirective } from './container-content-placeholder.directive';
import { ContainerNoResultDirective } from './container-no-result.directive';
import { ContainerSideContentDirective } from './container-side-content.directive';
import { ResizeGrabberComponent } from './resize-grabber.component';
import { GrabberDirective } from './grabber.directive';
import { TiimeIconModule } from '../icon';

@NgModule({
  imports: [CommonModule, TiimeIconModule],
  declarations: [
    ContainerComponent,
    ContainerContentDirective,
    ContainerFooterDirective,
    ContainerHeaderLeftDirective,
    ContainerHeaderRightDirective,
    ContainerHeaderDirective,
    ContainerOnboardDirective,
    ContainerContentPlaceholderDirective,
    ContainerNoResultDirective,
    ContainerSideContentDirective,
    ResizeGrabberComponent,
    GrabberDirective
  ],
  exports: [
    ContainerComponent,
    ContainerContentDirective,
    ContainerFooterDirective,
    ContainerHeaderLeftDirective,
    ContainerHeaderRightDirective,
    ContainerHeaderDirective,
    ContainerOnboardDirective,
    ContainerContentPlaceholderDirective,
    ContainerNoResultDirective,
    ContainerSideContentDirective
  ]
})
export class TiimeContainerModule {}
