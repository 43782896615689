import { Directive, ElementRef } from '@angular/core';

import { HasAttributesBase } from '../core/index';

@Directive({
  selector: '[tiimeContainerOnboard]',
  host: {
    class: 'tiime-container-onboard',
    '[class.tiime-container-onboard-with-header]':
      '_hasHostAttributes("tiimeContainerOnboardWithHeader")'
  }
})
export class ContainerOnboardDirective extends HasAttributesBase {
  constructor(elementRef: ElementRef) {
    super(elementRef);
  }
}
