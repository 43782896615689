import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal, ComponentType, PortalInjector } from '@angular/cdk/portal';
import { ComponentRef, Injectable, Injector } from '@angular/core';

import { TiimeOverlayConfig } from './overlay.config';
import { TiimeOverlayRef } from './overlay.ref';
import { TIIME_OVERLAY_DATA } from './overlay.token';

const TIIME_OVERLAY_DEFAULT_CONFIG: TiimeOverlayConfig = {
  panelClass: 'tiime-overlay-panel',
  backdropClass: 'cdk-overlay-dark-backdrop',
  hasBackdrop: false,
  backdropClose: false
};

@Injectable()
export class TiimeOverlayService {
  constructor(private injector: Injector, private overlay: Overlay) {}

  open<T>(component: ComponentType<T>, data: any = null, config: TiimeOverlayConfig = {}): TiimeOverlayRef {
    const modalConfig = { ...TIIME_OVERLAY_DEFAULT_CONFIG, ...config };
    if (!modalConfig.width && !modalConfig.maxWidth && !modalConfig.minWidth) {
      modalConfig.width = '100%';
    }
    if (!modalConfig.height && !modalConfig.maxHeight && !modalConfig.minHeight) {
      modalConfig.height = '100%';
    }
    const overlayRef = this.createOverlay(modalConfig);
    const dialogRef = new TiimeOverlayRef(overlayRef);
    this.attachModalContainer<T>(overlayRef, dialogRef, component, data);
    this.backdropSubscription(modalConfig, overlayRef, dialogRef);
    return dialogRef;
  }

  private attachModalContainer<T>(
    overlayRef: OverlayRef,
    dialogRef: TiimeOverlayRef,
    component: ComponentType<T>,
    data: any
  ): T {
    const injector = this.createInjector(dialogRef, data);
    const containerPortal = new ComponentPortal(component, null, injector);
    const containerRef: ComponentRef<T> = overlayRef.attach(containerPortal);
    return containerRef.instance;
  }

  private createOverlay(config: TiimeOverlayConfig): OverlayRef {
    const overlayConfig = this.getOverlayConfig(config);
    return this.overlay.create(overlayConfig);
  }

  private createInjector(dialogRef: TiimeOverlayRef, data: any): PortalInjector {
    const injectionTokens = new WeakMap();
    injectionTokens.set(TiimeOverlayRef, dialogRef);
    injectionTokens.set(TIIME_OVERLAY_DATA, data);
    return new PortalInjector(this.injector, injectionTokens);
  }

  private getOverlayConfig(config: TiimeOverlayConfig): OverlayConfig {
    return new OverlayConfig({
      panelClass: config.panelClass,
      width: config.width,
      height: config.height,
      backdropClass: config.backdropClass,
      hasBackdrop: config.hasBackdrop,
      positionStrategy: config.connectTo
        ? this.overlay
            .position()
            .flexibleConnectedTo(config.connectTo.origin)
            .withPositions(config.connectTo.positions)
            .withPush(true)
        : null
    });
  }

  private backdropSubscription(config: TiimeOverlayConfig, overlayRef: OverlayRef, dialogRef: TiimeOverlayRef): void {
    if (config.backdropClose) {
      overlayRef.backdropClick().subscribe(() => dialogRef.close());
    }
  }
}
