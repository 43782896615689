<app-auth-layout>
  <form class="auth-form" [formGroup]="signInForm" (ngSubmit)="submitSignInForm()">
    <div tiimeForm>
      <div tiimeFormColumn>
        <div class="auth-form-header">
          <div class="auth-form-header-illustration"></div>
          <span class="auth-form-header-title">Connectez-vous</span>
        </div>
        <div class="auth-form-content">
          <div class="input-container">
            <input type="text" placeholder="Email" formControlName="email" />
            <label class="input-error-label" *ngIf="signInForm.emailErrorMessage">
              {{ signInForm.emailErrorMessage }}
            </label>
          </div>
          <div class="input-container">
            <input type="password" placeholder="Mot de passe" formControlName="password" />
            <label class="input-error-label" *ngIf="signInForm.passwordErrorMessage">
              {{ signInForm.passwordErrorMessage }}
            </label>
          </div>
          <button tiime-button color="accent" raised large>Je me connecte</button>
        </div>
        <div class="auth-form-link bottom">
          <a [routerLink]="changePasswordRouterLink">Mot de passe oublié ?</a>
        </div>
      </div>
    </div>
  </form>
</app-auth-layout>
