import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { TiimePipesModule } from 'tiime-utils';

import { TiimeIconModule } from '../icon/index';
import { PaginatorComponent } from './paginator.component';

@NgModule({
  imports: [CommonModule, TiimeIconModule, TiimePipesModule],
  declarations: [PaginatorComponent],
  exports: [PaginatorComponent]
})
export class TiimePaginatorModule {}
