import { TextFieldModule } from '@angular/cdk/text-field';
import { NgModule } from '@angular/core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MAT_DATE_FORMATS, MAT_DATE_LOCALE, MAT_RIPPLE_GLOBAL_OPTIONS } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MomentDateModule } from '@angular/material-moment-adapter';

import {
  TiimeButtonModule,
  TiimeButtonToggleModule,
  TiimeCheckboxModule,
  TiimeColumnsSelectorModule,
  TiimeDatepickerModule,
  TiimeDialogModule,
  TiimeEmptyStateModule,
  TiimeIconModule,
  TiimeInputContainerModule,
  TiimeMenuModule,
  TiimeOverlayModule,
  TiimePaginatorModule,
  TiimeRadioModule,
  TiimeSearchBarModule,
  TiimeSidenavLayoutModule,
  TiimeSlideToggleModule,
  TiimeSnackbarModule,
  TiimeSortModule,
  TiimeSubheaderModule,
  TiimeTableModule,
  TiimeTagModule,
  TiimeTooltipModule,
  TiimeFormModule,
  TiimeTwocolorsIconModule,
  TiimeContainerModule,
  TIIME_DATE_FORMATS,
  TiimeCardModule,
  TiimeListModule,
  TIIME_RIPPLE_GLOBAL_OPTIONS,
  TiimeAutoSaveStatusModule,
  TiimeSelectModule,
  TiimeSecondaryFiltersModule,
  TiimeFileModule
} from 'tiime-material';

@NgModule({
  imports: [MomentDateModule],
  providers: [
    {
      provide: MAT_RIPPLE_GLOBAL_OPTIONS,
      useValue: TIIME_RIPPLE_GLOBAL_OPTIONS
    },
    { provide: MAT_DATE_FORMATS, useValue: TIIME_DATE_FORMATS },
    { provide: MAT_DATE_LOCALE, useValue: 'fr-FR' }
  ],
  exports: [
    TiimeColumnsSelectorModule,
    TiimeRadioModule,
    TiimeContainerModule,
    MatAutocompleteModule,
    MatMenuModule,
    MatRadioModule,
    MatDatepickerModule,
    MatProgressSpinnerModule,
    TextFieldModule,
    TiimeSlideToggleModule,
    TiimeButtonModule,
    TiimePaginatorModule,
    TiimeTableModule,
    TiimeSearchBarModule,
    TiimeTagModule,
    TiimeIconModule,
    TiimeSnackbarModule,
    TiimeSubheaderModule,
    TiimeEmptyStateModule,
    TiimeMenuModule,
    TiimeDialogModule,
    TiimeInputContainerModule,
    TiimeTwocolorsIconModule,
    TiimeTooltipModule,
    TiimeCardModule,
    TiimeSortModule,
    TiimeOverlayModule,
    TiimeButtonToggleModule,
    TiimeListModule,
    TiimeDatepickerModule,
    TiimeCheckboxModule,
    TiimeSidenavLayoutModule,
    TiimeFormModule,
    TiimeAutoSaveStatusModule,
    TiimeSelectModule,
    TiimeSecondaryFiltersModule,
    TiimeFileModule
  ]
})
export class MaterialModule {}
