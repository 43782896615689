import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiCommonService } from '@api-services/api-common.service';
import { SERVICES_URL } from '@constants/services-url';
import { ApiAlertError } from '@decorators/api-alert-error';
import { User, UserJson } from '@models/user/user';

/**
 * User Service
 */
@Injectable({
  providedIn: 'root'
})
export class UserApiService extends ApiCommonService<User, UserJson> {
  resourceUrl = SERVICES_URL.USER;

  constructor(http: HttpClient) {
    super(http);
  }

  fromJson(json: UserJson): User {
    return User.fromJson(json);
  }

  toJson(model: User): UserJson {
    return User.toJson(model);
  }

  @ApiAlertError()
  getUserMe(): Observable<User> {
    return this.http
      .get(this.resourceUrl)
      .pipe(map((userJson: UserJson) => User.fromJson(userJson)));
  }
}
