import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ListComponent } from './list.component';
import { ListItemComponent } from './list-item.component';
import { ListContentPlaceholderComponent } from './list-content-placeholder.component';

@NgModule({
  imports: [CommonModule],
  declarations: [
    ListComponent,
    ListItemComponent,
    ListContentPlaceholderComponent
  ],
  exports: [ListComponent, ListItemComponent, ListContentPlaceholderComponent]
})
export class TiimeListModule {}
