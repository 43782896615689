import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatCheckboxModule } from '@angular/material/checkbox';

import { TiimePipesModule } from 'tiime-utils';

import { ColumnsSelectorButtonComponent } from './columns-selector-button.component';
import { ColumnsSelectorComponent } from './columns-selector.component';

@NgModule({
  declarations: [ColumnsSelectorButtonComponent, ColumnsSelectorComponent],
  imports: [CommonModule, MatCheckboxModule, TiimePipesModule],
  exports: [ColumnsSelectorButtonComponent, ColumnsSelectorComponent]
})
export class TiimeColumnsSelectorModule {}
