import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import * as Sentry from '@sentry/browser';
import { Integrations as TracingIntegrations } from '@sentry/tracing';

import { environment } from '@env';
import { User } from '@models/user/user';

@Injectable({
  providedIn: 'root'
})
export class SentryService {
  init(): void {
    if (environment.SENTRY_DNS) {
      Sentry.init({
        integrations: [new TracingIntegrations.BrowserTracing()],
        tracesSampleRate: 0.2,
        dsn: environment.SENTRY_DNS,
        environment: environment.ENV_NAME,
        release: environment.VERSION,
        beforeSend(event, hint) {
          const error = hint.originalException;
          if (error && error instanceof HttpErrorResponse) {
            return null;
          }

          return event;
        }
      });
    }
  }

  captureException(error: Error & { originalError?: Error }): void {
    if (environment.SENTRY_DNS) {
      Sentry.captureException(error.originalError || error);
    } else {
      console.error(error);
    }
  }

  cleanUser(): void {
    if (environment.SENTRY_DNS) {
      Sentry.configureScope(scope => scope.setUser(null));
    }
  }

  setUser(user: User): void {
    if (environment.SENTRY_DNS) {
      Sentry.configureScope(scope =>
        scope.setUser({
          id: String(user.id),
          email: user.email
        })
      );
    }
  }
}
