export enum AppRoute {
  empty = '',
  signin = 'signin',
  changePassword = 'changepassword',
  authCallback = 'auth-callback',
  unauthorized = 'unauthorized',
  notFound = 'not-found',
  admin = 'admin',
  others = '**'
}
