import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';

import { TiimeAuthService } from 'tiime-auth';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard {
  constructor(private authService: TiimeAuthService, private router: Router) {}

  canLoad(): boolean | UrlTree {
    return this.isAuthorized();
  }

  private isAuthorized(): boolean | UrlTree {
    if (!this.authService.isAuthenticated()) {
      return this.router.createUrlTree(['/signin']);
    }

    return true;
  }
}
