import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MAT_DIALOG_DEFAULT_OPTIONS, MatDialogModule } from '@angular/material/dialog';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { TiimeButtonModule } from '../button';
import { TiimeIconModule } from '../icon';
import { DialogComponent } from './dialog.component';

@NgModule({
  imports: [
    CommonModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    TiimeIconModule,
    TiimeButtonModule,
    DragDropModule
  ],
  declarations: [DialogComponent],
  exports: [MatDialogModule, DialogComponent],
  providers: [
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: { panelClass: 'tiime-dialog-panel', hasBackdrop: true, closeOnNavigation: true, disableClose: true }
    }
  ]
})
export class TiimeDialogModule {}
