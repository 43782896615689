import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';

import { tap } from 'rxjs/operators';

import { TiimeAuthService } from 'tiime-auth';
import { MarkFormAsTouchedIfInvalid } from 'tiime-utils';

import { AppRoute } from '@navigation/app-route.enum';

import { ChangePasswordForm } from './change-password-form';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['../auth-base.scss', './change-password.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChangePasswordComponent implements OnInit {
  changePasswordForm: ChangePasswordForm;
  isMailSent = false;

  readonly signinRouterLink = [`/${AppRoute.signin}`];

  constructor(private authService: TiimeAuthService, private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.initChangePasswordForm();
  }

  @MarkFormAsTouchedIfInvalid('changePasswordForm')
  submitChangePasswordForm(): void {
    this.changePassword();
  }

  private initChangePasswordForm(): void {
    this.changePasswordForm = new ChangePasswordForm();
  }

  private changePassword(): void {
    this.authService
      .changePassword(this.changePasswordForm.email.value)
      .pipe(tap(() => this.changePasswordSuccess()))
      .subscribe();
  }

  private changePasswordSuccess(): void {
    this.isMailSent = true;
    this.cdr.markForCheck();
  }
}
