<button
  class="secondary-filters-btn"
  tiime-button
  color="primary"
  strocked
  cdkOverlayOrigin
  [matBadge]="badge"
  matBadgeSize="small"
  matBadgePosition="after"
  [matBadgeHidden]="badgeHidden"
  data-cy="secondary-filters-button"
  #origin="cdkOverlayOrigin"
  (click)="openPanel()"
>
  Filtrer
  <mat-icon class="secondary-filters-btn-icon" fontIcon="icon-ic-filter"></mat-icon>
</button>

<ng-template
  cdkConnectedOverlay
  cdkConnectedOverlayHasBackdrop
  cdkConnectedOverlayBackdropClass="cdk-overlay-transparent-backdrop"
  [cdkConnectedOverlayOrigin]="origin"
  [cdkConnectedOverlayOpen]="panelOpen"
  [cdkConnectedOverlayOffsetY]="offsetY"
  (backdropClick)="closePanel()"
  (detach)="closePanel()"
>
  <div class="secondary-filters-panel" data-cy="secondary-filters-container">
    <ng-content></ng-content>
  </div>
</ng-template>
