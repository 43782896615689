import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'tiime-sidenav-layout',
  templateUrl: './sidenav-layout.component.html',
  styleUrls: ['./sidenav-layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TiimeSidenavLayoutComponent {
  @Input() disableToogle = false;
  @Input() opened = true;

  constructor() {}

  onToogleSidenav(): void {
    this.opened = !this.opened;
  }
}
