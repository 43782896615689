import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { tap, withLatestFrom } from 'rxjs/operators';

import { User } from '@models/user/user';
import { SentryService } from '@modules/core/sentry/sentry.service';
import { UPDATE } from '@store/user/user-actions';
import { userSelector } from '@store/user/user-selector';

@Injectable()
export class UserEffects {
  constructor(private store: Store, private actions$: Actions, private sentryService: SentryService) {}

  updateAction$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UPDATE),
        withLatestFrom(this.store.select(userSelector)),
        tap(([, user]: [any, User]) => this.sentryService.setUser(user))
      ),
    { dispatch: false }
  );
}
