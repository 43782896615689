import { Component, OnInit } from '@angular/core';

import { throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { TiimeAuthService } from 'tiime-auth';

import { MainRoute } from '@navigation/main-route.enum';
import { NavigationService } from '@navigation/navigation.service';

@Component({
  selector: 'app-auth-callback',
  templateUrl: './auth-callback.component.html',
  styleUrls: ['./auth-callback.component.scss']
})
export class AuthCallbackComponent implements OnInit {
  constructor(private authService: TiimeAuthService, private navigationService: NavigationService) {}

  ngOnInit(): void {
    this.authService
      .parseHash()
      .pipe(
        tap(() => this.navigationService.navigate([MainRoute.companiesInitialization])),
        catchError(error => {
          this.navigationService.navigateToSignIn();
          return throwError(error);
        })
      )
      .subscribe();
  }
}
