import { ElementRef } from '@angular/core';

export abstract class HasAttributesBase {
  elementRef: ElementRef;

  protected constructor(elementRef: ElementRef) {
    this.elementRef = elementRef;
  }

  /** Gets whether the button has one of the given attributes and the attributes values are not false */
  _hasHostAttributes(...attributes: string[]): boolean {
    return attributes.some(
      attribute =>
        !!this._getAttributeValue(attribute) &&
        this._getAttributeValue(attribute) !== 'false'
    );
  }

  private _getHostElement(): any {
    return this.elementRef.nativeElement;
  }

  /** Gets value of the button attributes or return */
  private _getAttributeValue(attribute): any {
    if (this._getHostElement().hasAttribute(attribute)) {
      const value = this._getHostElement().getAttribute(attribute);
      if (value !== '' && value !== null) {
        return value;
      }
      return true;
    }
    return false;
  }
}
