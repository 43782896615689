import { _DisposeViewRepeaterStrategy, _VIEW_REPEATER_STRATEGY } from '@angular/cdk/collections';
import {
  _COALESCED_STYLE_SCHEDULER,
  _CoalescedStyleScheduler,
  CDK_TABLE,
  CDK_TABLE_TEMPLATE,
  CdkTable,
  STICKY_POSITIONING_LISTENER
} from '@angular/cdk/table';
import { ChangeDetectionStrategy, Component, ElementRef, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'tiime-table, table[tiime-table]',
  template: `
    <div class="columns-selector-container" *ngIf="columnsSelector">
      <tiime-columns-selector-button [minSelected]="minSelectedColumns"></tiime-columns-selector-button>
    </div>
    ${CDK_TABLE_TEMPLATE}
  `,
  styleUrls: ['./table.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    // https://github.com/angular/components/blob/69029b4b5988b8502821aa8688c1a9ac71f39950/src/cdk/table/table.ts#L220
    { provide: CDK_TABLE, useExisting: TableComponent },
    {
      provide: _VIEW_REPEATER_STRATEGY,
      useClass: _DisposeViewRepeaterStrategy
    },
    { provide: _COALESCED_STYLE_SCHEDULER, useClass: _CoalescedStyleScheduler },
    // Prevent nested tables from seeing this table's StickyPositioningListener.
    { provide: STICKY_POSITIONING_LISTENER, useValue: null }
  ]
})
export class TableComponent<T> extends CdkTable<T> {
  @Input() columnsSelector: boolean;
  @Input() minSelectedColumns = 1;

  // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match
  readonly _elementRef: ElementRef;
}
