import { Platform } from '@angular/cdk/platform';
import {
  ChangeDetectionStrategy,
  Component,
  ContentChildren,
  ElementRef,
  Input,
  OnChanges,
  QueryList,
  ViewEncapsulation
} from '@angular/core';

import { HasAttributesBase } from '../core/index';
import { InputSuffixDirective } from './input-suffix';

@Component({
  selector: 'tiime-input-container',
  host: {
    '[class.firefox]': 'platform.FIREFOX',
    '[class.label-margin]': 'label',
    '[class.small]': '_hasHostAttributes("small")',
    '[class.large]': '_hasHostAttributes("large")',
    class: 'tiime-input-container'
  },
  templateUrl: './input-container.component.html',
  styleUrls: ['input-container.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class InputContainerComponent extends HasAttributesBase implements OnChanges {
  @Input()
  contentId: string;
  @Input()
  label: string;
  @Input()
  errorMessage: string;
  @Input() required = false;

  @ContentChildren(InputSuffixDirective)
  set setSuffix(suffix: QueryList<InputSuffixDirective>) {
    this.displaySuffix = !!suffix.length;
  }

  displaySuffix = false;
  placeholder: string;

  constructor(elementRef: ElementRef, public platform: Platform) {
    super(elementRef);
  }

  ngOnChanges(): void {
    this.setPlaceholder();
  }

  private setPlaceholder(): void {
    this.placeholder = this.label + (this.required ? '*' : '');
  }
}
