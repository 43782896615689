import { AnimationEvent } from '@angular/animations';
import { CdkPortal } from '@angular/cdk/portal';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
  TemplateRef,
  ViewContainerRef,
  ViewEncapsulation
} from '@angular/core';

import { Observable, Subject } from 'rxjs';

import { tiimeTooltipAnimations } from './tooltip-animation';

const TOOLTIP_DEFAULT_DELAY = 300;

@Component({
  selector: 'tiime-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [tiimeTooltipAnimations.tooltipState]
})
export class TooltipComponent implements OnInit {
  cdkPortal: CdkPortal;
  tooltipContent: TemplateRef<any>;
  tooltipClass: string | string[] | Set<string> | { [key: string]: any };
  visibility = 'initial';

  private hideTimeoutId: ReturnType<typeof setTimeout> | undefined;
  private showTimeoutId: ReturnType<typeof setTimeout> | undefined;

  /** Subject for notifying that the tooltip has been hidden from the view */
  private readonly onHide: Subject<void> = new Subject();

  constructor(private cdr: ChangeDetectorRef, private viewContainerRef: ViewContainerRef) {}

  ngOnInit(): void {
    this.cdkPortal = new CdkPortal(this.tooltipContent, this.viewContainerRef);
  }

  afterHidden(): Observable<void> {
    return this.onHide.asObservable();
  }

  animationDone(event: AnimationEvent): void {
    const toState = event.toState;
    if (toState === 'hidden') {
      this.onHide.next();
    }
  }

  hide(delay = TOOLTIP_DEFAULT_DELAY): void {
    // Cancel the delayed show if it is scheduled
    if (this.showTimeoutId) {
      clearTimeout(this.showTimeoutId);
    }
    this.hideTimeoutId = setTimeout(() => {
      this.visibility = 'hidden';
      this.hideTimeoutId = undefined;
      this.markForCheck();
    }, delay);
  }

  markForCheck(): void {
    this.cdr.markForCheck();
  }

  show(delay = TOOLTIP_DEFAULT_DELAY): void {
    // Cancel the delayed hide if it is scheduled
    if (this.hideTimeoutId) {
      clearTimeout(this.hideTimeoutId);
    }
    this.showTimeoutId = setTimeout(() => {
      this.visibility = 'visible';
      this.showTimeoutId = undefined;
      this.markForCheck();
    }, delay);
  }
}
