import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { TiimeIconModule } from '../icon';
import {
  FormDirective,
  FormColumnDirective,
  FormInputDirective,
  FormTitleDirective,
  FormVerticalSeparatorDirective,
  FormRowDirective
} from './form';
import { FormComponent } from './form.component';

@NgModule({
  imports: [CommonModule, TiimeIconModule],
  declarations: [
    FormDirective,
    FormTitleDirective,
    FormColumnDirective,
    FormInputDirective,
    FormVerticalSeparatorDirective,
    FormRowDirective,
    FormComponent
  ],
  exports: [
    FormDirective,
    FormTitleDirective,
    FormColumnDirective,
    FormInputDirective,
    FormVerticalSeparatorDirective,
    FormRowDirective,
    FormComponent
  ]
})
export class TiimeFormModule {}
