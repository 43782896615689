import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { environment } from '@env';
import { AppRoute } from '@navigation/app-route.enum';
import { NavigationService } from '@navigation/navigation.service';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
  excludedForbiddenPatterns: Record<string, RegExp[]> = {
    get: [/v1\/data\/companies\/\d+\/bank_transactions_to_analyze(?!.*\/)/],
    patch: [/v1\/data\/companies\/\d+\/bank_transactions_to_analyze\/\d+(?!.*\/)/]
  };

  constructor(private navigationService: NavigationService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.url.startsWith('api/')) {
      const url = this.replaceApi(request.url);

      request = request.clone({
        url,
        setHeaders: {
          'tiime-app': 'tiime-data'
        }
      });
    }

    return next.handle(request).pipe(
      catchError(error => {
        if (error instanceof HttpErrorResponse) {
          if (error.status === 401) {
            void this.navigationService.navigateToSignIn();
          } else if (error.status === 403 && !this.isRequestExcluded(request)) {
            void this.navigationService.navigate([AppRoute.unauthorized]);
          }
        }

        return throwError(error);
      })
    );
  }

  private isRequestExcluded(request: HttpRequest<any>): boolean {
    const excluded = this.excludedForbiddenPatterns?.[request.method.toLowerCase()] ?? [];
    return excluded.some(pattern => pattern.test(request.url));
  }

  private replaceApi(url: string): string {
    return `${environment.API_URL}/${url.replace('api/', '')}`;
  }
}
