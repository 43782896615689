import { TiimeLetDirective } from './let.directive';

export class TiimeLetDirectiveContext<T> {
  get $implicit(): T {
    return this.directiveInstance.tiimeLet;
  }
  get tiimeLet(): T {
    return this.directiveInstance.tiimeLet;
  }

  constructor(private directiveInstance: TiimeLetDirective<T>) {}
}
