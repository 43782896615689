import { NgModule } from '@angular/core';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';

@NgModule({
  imports: [MatIconModule],
  exports: [MatIconModule],
  providers: [MatIconRegistry]
})
export class TiimeIconModule {
  constructor(public matIconRegistry: MatIconRegistry) {
    matIconRegistry.setDefaultFontSetClass('tiime-fontastic');
  }
}
