import { HttpErrorResponse } from '@angular/common/http';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { TiimeSnackbarService, SnackbarConfig } from 'tiime-material';

import { sharedInjector } from '@shared/shared-injector';

export function ApiAlertError(statusBlackList = [403, 404]): MethodDecorator {
  return (
    target: () => void,
    propertyKey: string,
    descriptor: PropertyDescriptor
  ) => {
    const original = descriptor.value;
    descriptor.value = function (...args: any[]) {
      return original.apply(this, args).pipe(
        catchError(error => {
          if (
            error instanceof HttpErrorResponse &&
            error.error &&
            error.error.error_description &&
            !statusBlackList.includes(error.status)
          ) {
            sharedInjector
              .get(TiimeSnackbarService)
              .open(error.error.error_description, SnackbarConfig.error);
          } else if (
            error.error instanceof Blob &&
            error.error.type === 'application/json'
          ) {
            const reader = new FileReader();
            reader.onloadend = () => {
              const fileError = JSON.parse(reader.result as string);
              if (fileError.error_description) {
                sharedInjector
                  .get(TiimeSnackbarService)
                  .open(fileError.error_description, SnackbarConfig.error);
              }
            };
            reader.readAsText(error.error);
          }

          return throwError(error);
        })
      );
    };
    return descriptor;
  };
}
