import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';

import { ContainerState } from '../container';

@Component({
  selector: 'tiime-sticky-table-container',
  templateUrl: './sticky-table-container.component.html',
  styleUrls: ['./sticky-table-container.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StickyTableContainerComponent {
  @Input() containerState: ContainerState;
}
