import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

import { RegexUtils } from 'tiime-utils';

export class ChangePasswordForm extends UntypedFormGroup {
  get email(): UntypedFormControl {
    return this.get('email') as UntypedFormControl;
  }
  get emailErrorMessage(): string {
    if (!this.email.touched) {
      return null;
    }

    if (this.email.hasError('required')) {
      return 'Email requis';
    }

    if (this.email.hasError('pattern')) {
      return 'Email invalide';
    }

    return null;
  }

  constructor() {
    super({
      email: new UntypedFormControl('', Validators.compose([Validators.pattern(RegexUtils.mailRegex), Validators.required]))
    });
  }
}
