import { NgModule } from '@angular/core';

import { JwtModule, TiimeAuthModule } from 'tiime-auth';

import { environment } from '@env';
import { AuthCallbackComponent } from '@modules/core/auth/auth-callback/auth-callback.component';
import { AuthLayoutComponent } from '@modules/core/auth/auth-layout/auth-layout.component';
import { ChangePasswordComponent } from '@modules/core/auth/change-password/change-password.component';
import { SigninComponent } from '@modules/core/auth/signin/signin.component';
import { SharedModule } from '@modules/shared/shared.module';

export function tokenGetter(): string {
  return localStorage.getItem('access_token');
}

@NgModule({
  imports: [
    SharedModule,
    TiimeAuthModule.forRoot({
      config: {
        AUTH0_AUDIENCE: environment.AUTH0_AUDIENCE,
        AUTH0_CLIENT_ID: environment.AUTH0_CLIENT_ID,
        AUTH0_DOMAIN: environment.AUTH0_DOMAIN,
        AUTH0_REALM: environment.AUTH0_REALM,
        AUTH0_WHITE_DOMAINS: environment.AUTH0_WHITE_DOMAINS
      }
    }),
    JwtModule.forRoot({
      config: {
        tokenGetter,
        allowedDomains: environment.AUTH0_WHITE_DOMAINS
      }
    })
  ],
  declarations: [AuthCallbackComponent, SigninComponent, AuthLayoutComponent, ChangePasswordComponent]
})
export class AuthModule {}
