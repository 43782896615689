import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';

import { ContainerState } from './container-state.enum';

@Component({
  selector: 'tiime-container',
  templateUrl: './container.component.html',
  styleUrls: ['./container.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'tiime-container'
  }
})
export class ContainerComponent {
  @Input() containerState: ContainerState;

  readonly ContainerState = ContainerState;
}
