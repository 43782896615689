import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { AutoSaveStatusComponent } from './auto-save-status.component';

@NgModule({
  imports: [CommonModule],
  declarations: [AutoSaveStatusComponent],
  exports: [AutoSaveStatusComponent]
})
export class TiimeAutoSaveStatusModule {}
