import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Params, Router } from '@angular/router';

import { AppRoute } from './app-route.enum';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {
  constructor(public router: Router) {}

  navigate(commands: any[], extras?: NavigationExtras): Promise<boolean> {
    return this.router.navigate(commands, extras);
  }

  navigateToDefaultRoute(): Promise<boolean> {
    return this.navigate([AppRoute.empty]);
  }

  navigateToSignIn(): Promise<boolean> {
    return this.navigate([AppRoute.signin]);
  }

  updateQueryParams(queryParams: Params, activatedRoute: ActivatedRoute): void {
    void this.navigate([], {
      relativeTo: activatedRoute,
      queryParams,
      queryParamsHandling: 'merge',
      preserveFragment: true,
      replaceUrl: true
    });
  }

  private openInNewTab(commands: any[], extras?: NavigationExtras): void {
    const url = this.router.serializeUrl(this.router.createUrlTree(commands, extras));
    window.open(url, '_blank', 'noopener,noreferrer');
  }
}
