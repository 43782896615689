import { Directive } from '@angular/core';

import { BehaviorSubject } from 'rxjs';

import { Mapper } from 'tiime-utils';

import { ContainerState } from './container-state.enum';

@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export abstract class ContainerBase {
  readonly isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  get isLoading(): boolean {
    return this.isLoading$.value;
  }

  set isLoading(isLoading: boolean) {
    this.isLoading$.next(isLoading);
  }

  readonly containerState$: BehaviorSubject<ContainerState> = new BehaviorSubject<ContainerState>(ContainerState.contentPlaceholder);

  get containerState(): ContainerState {
    return this.containerState$.value;
  }

  set containerState(containerState: ContainerState) {
    this.containerState$.next(containerState);
  }

  readonly mapToDisabledActions: Mapper<ContainerState, boolean> = (containerState: ContainerState) =>
    containerState !== ContainerState.done && containerState !== ContainerState.noResult;

  reset(): void {
    this.isLoading = true;
    this.containerState = ContainerState.contentPlaceholder;
  }
}
