import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { TiimeTooltipModule } from '../tooltip/index';
import { InputContainerComponent } from './input-container.component';
import { InputSuffixDirective } from './input-suffix';

@NgModule({
  imports: [CommonModule, TiimeTooltipModule],
  declarations: [InputContainerComponent, InputSuffixDirective],
  exports: [InputContainerComponent, InputSuffixDirective]
})
export class TiimeInputContainerModule {}
