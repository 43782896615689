<div
  class="select-origin"
  [class.select-origin-disabled]="disabled"
  cdkOverlayOrigin
  #origin="cdkOverlayOrigin"
  #trigger
  (click)="openPanel()"
>
  <ng-content select="[tiimeSelectCustomValue]"></ng-content>
  <ng-container *ngIf="!customValueTemplate">
    <div *ngIf="displayedValue; then selectValue; else selectPlaceholder"></div>
    <ng-template #selectValue>
      <ng-content select="[tiimeSelectPrefix]"></ng-content>
      <div class="select-value-container">
        <span tiimeTooltip tooltipHideWithoutEllipsis class="select-value" [tooltipContent]="tooltipTemplate">
          {{ displayedValue }}
        </span>
        <ng-template #tooltipTemplate>
          <div>{{ displayedValue }}</div>
        </ng-template>
        <ng-content select="[tiimeSelectInfos]"></ng-content>
      </div>
      <ng-content select="[tiimeSelectSuffix]"></ng-content>
    </ng-template>
    <ng-template #selectPlaceholder>
      <span class="select-placeholder">{{ placeholder }}</span>
    </ng-template>
  </ng-container>
  <div class="select-arrow" [class.open]="panelOpen"></div>
</div>
<ng-template
  cdkConnectedOverlay
  cdkConnectedOverlayHasBackdrop
  cdkConnectedOverlayBackdropClass="cdk-overlay-transparent-backdrop"
  [cdkConnectedOverlayOffsetX]="offsetX"
  [cdkConnectedOverlayOffsetY]="offsetY"
  [cdkConnectedOverlayViewportMargin]="viewportMargin"
  [cdkConnectedOverlayMinWidth]="panelMinWidth"
  [cdkConnectedOverlayOrigin]="origin"
  [cdkConnectedOverlayOpen]="panelOpen"
  (backdropClick)="closePanel()"
  (detach)="closePanel()"
>
  <div #panel class="select-panel" [ngClass]="panelClass" (keydown)="onKeyDown($event)">
    <div [class.select-input-container]="input">
      <ng-content select="[tiimeSelectInput]"></ng-content>
      <mat-progress-spinner *ngIf="isLoading" diameter="16" color="primary" mode="indeterminate"></mat-progress-spinner>
    </div>
    <ng-content select="[tiimeSelectOptions]"></ng-content>
  </div>
</ng-template>
