<div
  class="mat-sticky-table-content-placeholder-row"
  *ngFor="
    let contentPlaceholderRow of contentPlaceholderRows;
    trackBy: trackByIndex
  "
>
  <div>
    <div class="mat-sticky-table-content-placeholder-cell first"></div>
    <div class="mat-sticky-table-content-placeholder-cell"></div>
  </div>
  <div class="mat-sticky-table-content-placeholder last"></div>
</div>
