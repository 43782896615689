<div
  class="paginator-wrapper"
  [class.disabled]="range | map: mapToDisabled:disabled"
>
  <div
    class="pager"
    [class.disabled]="range | map: mapToHasNoPreviousPage"
    title="Précédent"
    (click)="previousPage()"
  >
    <mat-icon inline="true" fontIcon="icon-chevron-left"></mat-icon>
  </div>
  <span class="range">{{ range | map: mapToRangeLabel }}</span>
  <div
    class="pager"
    [class.disabled]="range | map: mapToHasNoNextPage"
    title="Suivant"
    (click)="nextPage()"
  >
    <mat-icon inline="true" fontIcon="icon-chevron-right"></mat-icon>
  </div>
</div>
