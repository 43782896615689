import { Directive } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export abstract class DialogBase<T, R = any> {
  isLoading = false;

  constructor(protected dialogRef: MatDialogRef<T>) {}

  cancel(): void {
    this.dialogRef.close();
  }

  close(data: R): void {
    this.dialogRef.close(data);
  }
}
