import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { PaginationData, PaginationRange } from 'tiime-material';

import { HttpHelper } from '@helpers/http.helper';

/**
 * API Common Service
 */
@Injectable({
  providedIn: 'root'
})
export abstract class ApiCommonService<T, Json> {
  abstract resourceUrl: string;
  abstract fromJson(json: Json): T;
  abstract toJson(model: T): Partial<Json>;

  protected constructor(protected http: HttpClient) {}

  /**
   * Get entity by identifier.
   *
   * @param id the entity identifier.
   * @returns the funded entity.
   */
  protected getById(id: number): Observable<T> {
    const url = `${this.resourceUrl}/${id}`;
    return this.http.get(url).pipe(map((json: Json) => this.fromJson(json)));
  }

  /**
   * Get all entities.
   *
   * @param options the http call options. It can include http params and http headers.
   * @param url the endpoint url if it is different than default resource url.
   * @returns the entities.
   */
  protected getAll(
    options?: { params?: HttpParams; headers?: HttpHeaders },
    url: string = this.resourceUrl
  ): Observable<T[]> {
    return this.http
      .get(url, options || {})
      .pipe(map((jsonArray: Json[]) => jsonArray.map((json: Json) => this.fromJson(json))));
  }

  /**
   * Get paginated entities.
   *
   * @param range the paginated range.
   * @param params the http parameters.
   * @param url  the endpoint url if it is different than default resource url.
   * @returns the paginated entities.
   */
  protected getPaginated(
    range: PaginationRange,
    params: HttpParams,
    url: string = this.resourceUrl
  ): Observable<PaginationData<T>> {
    const headers = HttpHelper.setRangeHeader(new HttpHeaders(), range);
    const options = { params, headers };

    return this.http.get(url, { ...options, observe: 'response' }).pipe(
      filter((response: HttpResponse<Json[]>) => response.status !== 204),
      HttpHelper.mapToPaginationData(range, json => this.fromJson(json))
    );
  }

  /**
   * Create an entity.
   *
   * @param data the entity data.
   * @param url the endpoint url if it is different than default resource url.
   * @returns the created entity.
   */
  protected create(data: T, url: string = this.resourceUrl): Observable<T> {
    const body = data ? this.toJson(data) : null;
    return this.http.post(url, body).pipe(map((json: any) => this.fromJson(json)));
  }

  /**
   * Update an entity.
   *
   * @param id the entity identifier to update.
   * @param data the entity data.
   * @returns the updated entity.
   */
  protected update(id: number, data: T): Observable<T> {
    const url = `${this.resourceUrl}/${id}`;
    return this.http.patch(url, this.toJson(data)).pipe(map((json: any) => this.fromJson(json)));
  }

  /**
   * Create or update an entity.
   *
   * @param data the entity data.
   * @returns the created or updated entity.
   */
  protected createOrUpdate(data: T): Observable<T> {
    const body = data ? this.toJson(data) : null;
    return this.http.put(this.resourceUrl, body).pipe(map((json: any) => this.fromJson(json)));
  }

  /**
   * Delete an entity.
   *
   * @param id the entity identifier to delete.
   */
  protected delete(id: number, url: string = this.resourceUrl): Observable<unknown> {
    const deleteUrl = `${url}/${id}`;
    return this.http.delete(deleteUrl);
  }
}
