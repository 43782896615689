import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';

import { TiimeSnackbarModule } from './snackbar.module';
import { SnackbarComponent } from './snackbar.component';

@Injectable({
  providedIn: TiimeSnackbarModule
})
export class TiimeSnackbarService {
  constructor(private matSnackbar: MatSnackBar) {}

  open(message: string, config: MatSnackBarConfig): void {
    this.matSnackbar.openFromComponent(SnackbarComponent, {
      ...config,
      data: message
    });
  }
}
