export const SERVICES_URL = {
  BANK_ACCOUNT: 'api/v1/data/bank_accounts',
  BANK_TRANSACTION: 'api/v1/data/bank_transactions',
  BANK_TRANSACTION_TO_ANALYZE: 'api/v1/data/bank_transactions_to_analyze',
  RISKY_TRANSACTION: 'api/v1/data/risky_transactions',
  RISKY_TRANSACTION_BY_CARD: 'api/v1/data/risky_transactions_by_card',
  USER: 'api/v1/data/users/me',
  REGEX: 'api/v1/data/global_bank_transaction_analyze_rules',
  DOCUMENTS: 'api/v1/data/documents',
  DUPLICATE_DOCUMENTS_TO_ANALYZE: 'api/v1/data/duplicate_documents_to_analyze',
  RECEIPTS: 'api/v1/data/receipts',
  INVOICES: 'api/v1/data/invoices',
  COMPANIES: 'api/v1/data/companies',
  STANDARD_LABELS: 'api/v1/data/standard_labels',
  FUNGIBLE_LABELS: 'api/v1/data/fungible_labels',
  LEXICON_LABELS: 'api/v1/data/lexicon_category_labels',
  BANK_TRANSACTION_BALANCE: 'api/v1/data/global_bank_transaction_analyze_rules',
  ACCOUNTING_LABEL: 'api/v1/data/accounting_labels',
  PARTNERS: 'api/v1/data/admin/partners',
  SQUADS: 'api/v1/data/admin/squads',
  DATA_USERS: 'api/v1/data/admin/users',
  ROLES: 'api/v1/data/roles',
  APE_CODES: 'api/v1/ape_codes',
  LEGAL_FORMS: 'api/v1/legal_forms',
  INVOICE_MATCHINGS: 'api/v1/data/invoice_matchings'
};
