import { Directive, QueryList, ViewChildren } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

import { Mapper } from 'tiime-utils';

import { AutoSaveStatus, AutoSaveStatusComponent } from '../auto-save-status';
import { ContainerBase, ContainerState } from '../container';
import { PaginationRange } from '../paginator';

@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export abstract class TableDataSourceBase<T> extends ContainerBase {
  @ViewChildren(AutoSaveStatusComponent)
  autoSaveStatusComponent: QueryList<AutoSaveStatusComponent>;

  dataSource: MatTableDataSource<T> = new MatTableDataSource<T>();
  filterValue: string = null;
  rangeValue: PaginationRange = new PaginationRange();
  sortValue: Sort = null;

  readonly mapToDisabledPaginator: Mapper<ContainerState, boolean> = (containerState: ContainerState) =>
    containerState !== ContainerState.done;
  readonly mapToDisabledSearchBar: Mapper<ContainerState, boolean> = (containerState: ContainerState) =>
    containerState !== ContainerState.done && containerState !== ContainerState.noResult;
  readonly mapToDisabledSort: Mapper<ContainerState, boolean> = (containerState: ContainerState) =>
    containerState !== ContainerState.done;
  readonly mapToHiddenFooter: Mapper<ContainerState, boolean> = (containerState: ContainerState) =>
    containerState !== ContainerState.done;

  resetTable(): void {
    this.filterValue = null;
    this.rangeValue = new PaginationRange();
    this.sortValue = null;
    this.dataSource.data = [];
    this.reset();
  }

  /**
   * Déclenche l'affichage de l'icône de succès pour l'élément fourni
   */
  protected saveSuccess(element: T, timeout = 2000): void {
    const autoSaveStatusComponent = this.findAutoSave(element);
    if (autoSaveStatusComponent) {
      autoSaveStatusComponent.updateAutoSaveStatus(AutoSaveStatus.SaveSuccess, timeout);
    }
  }

  /**
   * Déclenche l'affichage de l'icône d'erreur pour l'élément fourni
   */
  protected saveError(element: T, timeout = null): void {
    const autoSaveStatusComponent = this.findAutoSave(element);
    if (autoSaveStatusComponent) {
      autoSaveStatusComponent.updateAutoSaveStatus(AutoSaveStatus.SaveError, timeout);
    }
  }

  /**
   * Retourne l'AutoSaveStatusComponent correspondant à l'élément fourni
   */
  private findAutoSave(element: T): AutoSaveStatusComponent | undefined {
    const index = this.dataSource.data.findIndex(e => this.getId(e) === this.getId(element));
    if (index === -1) {
      return;
    }
    return this.autoSaveStatusComponent.find((_, i) => i === index);
  }

  /**
   * Retourne l'id d'un élément, que ce soit un objet de la DB ou un formulaire
   */
  private getId(element: any): number {
    const throwError = (message: string): void => {
      console.error(element);
      throw new Error(message);
    };

    if (!(element instanceof UntypedFormGroup)) {
      // eslint-disable-next-line no-prototype-builtins
      if (!element.hasOwnProperty('id')) {
        throwError(`Element does not have property id`);
      }
      return element.id;
    }
    const control = element.get('id');
    if (!control) {
      throwError('FormGroup does not have control id');
    }
    const value = element.get('id').value;
    if (!value) {
      throwError('FormGroup.id value is not set');
    }

    return value;
  }
}
