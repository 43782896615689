import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { TiimeOverlayComponent } from './overlay.component';
import { TiimeOverlayService } from './overlay.service';

@NgModule({
  imports: [CommonModule, ScrollingModule],
  declarations: [TiimeOverlayComponent],
  providers: [TiimeOverlayService],
  exports: [TiimeOverlayComponent]
})
export class TiimeOverlayModule {}
