import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { SelectCustomValueDirective } from './select-custom-value.directive';
import { SelectInfosDirective } from './select-infos.directive';
import { SelectInputDirective } from './select-input.directive';
import { SelectOptionsDirective } from './select-options.directive';
import { SelectComponent } from './select.component';
import { TiimeIconModule } from '../icon/index';
import { TiimeTooltipModule } from '../tooltip';

@NgModule({
  imports: [CommonModule, TiimeIconModule, TiimeTooltipModule, OverlayModule, MatProgressSpinnerModule],
  declarations: [
    SelectComponent,
    SelectCustomValueDirective,
    SelectInputDirective,
    SelectOptionsDirective,
    SelectInfosDirective
  ],
  exports: [
    SelectComponent,
    SelectCustomValueDirective,
    SelectInputDirective,
    SelectOptionsDirective,
    SelectInfosDirective
  ]
})
export class TiimeSelectModule {}
