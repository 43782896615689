<div class="columns-selector">
  <ng-container *ngIf="columns$ | async as columns">
    <ng-container *ngFor="let column of columns; trackBy: trackByIndex">
      <mat-checkbox
        color="primary"
        (change)="onColumnChanged(column)"
        [checked]="!column.hidden"
        [disabled]="column.disabled || (columns | map: mapToDisable:minSelected:column)"
      >
        {{ column.label }}
      </mat-checkbox>
    </ng-container>
  </ng-container>
</div>
