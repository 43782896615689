import { NgModule } from '@angular/core';
import { MatSnackBarModule } from '@angular/material/snack-bar';

import { SnackbarComponent } from './snackbar.component';
import { TiimeIconModule } from '../icon/icon.module';

@NgModule({
    imports: [MatSnackBarModule, TiimeIconModule],
    declarations: [SnackbarComponent],
    exports: [SnackbarComponent]
})
export class TiimeSnackbarModule {}
