import { Directive, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
  selector: '[tiimeGrabber]'
})
export class GrabberDirective {
  @HostListener('mousedown', ['$event']) mousedown = (e: MouseEvent): void => {
    this.startOffsetX = e.clientX;
    document.addEventListener('mousemove', this.handleDragging);
    document.addEventListener('mouseup', this.handleDragEnd);
    this.dragStarted.emit();
  };

  @Output() dragStarted: EventEmitter<null> = new EventEmitter<null>();
  @Output() dragMoved: EventEmitter<number> = new EventEmitter<number>();
  @Output() dragEnded: EventEmitter<null> = new EventEmitter<null>();

  startOffsetX = 0;

  readonly handleDragging = (event: MouseEvent): void => this.dragging(event);
  readonly handleDragEnd = (event: MouseEvent): void => this.dragEnd(event);

  private dragging(e: MouseEvent): void {
    const diff = e.clientX - this.startOffsetX;
    this.dragMoved.emit(diff);
  }

  private dragEnd(e: MouseEvent): void {
    this.startOffsetX = 0;
    document.removeEventListener('mousemove', this.handleDragging);
    document.removeEventListener('mouseup', this.handleDragEnd);
    this.dragEnded.emit();
  }
}
