<ng-container *ngIf="mode === 'icon'; else textMode">
  <ng-container [ngSwitch]="autoSaveStatus">
    <img data-cy="autosave-status-success"
         class="success"
         src="dist/tiime-material/assets/ic_check.svg"
         *ngSwitchCase="AutoSaveStatus.SaveSuccess"
    />
    <img data-cy="autosave-status-error"
         class="error"
         src="dist/tiime-material/assets/ic_warn.svg"
         *ngSwitchCase="AutoSaveStatus.SaveError"
    />
  </ng-container>
</ng-container>
<ng-template #textMode>
  <ng-container [ngSwitch]="autoSaveStatus">
    <span data-cy="autosave-status-text-begin" class="begin" *ngSwitchCase="AutoSaveStatus.BeginSave">Enregistrement en cours...</span>
    <span data-cy="autosave-status-text-success" class="success" *ngSwitchCase="AutoSaveStatus.SaveSuccess">Enregistré !</span>
    <span data-cy="autosave-status-text-error" class="error" *ngSwitchCase="AutoSaveStatus.SaveError">Erreur lors de l'enregistrement</span>
  </ng-container>

</ng-template>
