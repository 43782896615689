import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { TiimeButtonModule } from '../button/index';
import { TiimeIconModule } from '../icon/index';
import { TiimeTooltipModule } from '../tooltip/index';
import { TiimeSidenavLayoutComponent } from './sidenav-layout.component';

@NgModule({
  imports: [
    CommonModule,
    TiimeIconModule,
    TiimeButtonModule,
    TiimeTooltipModule
  ],
  declarations: [TiimeSidenavLayoutComponent],
  exports: [TiimeSidenavLayoutComponent]
})
export class TiimeSidenavLayoutModule {}
