import { MatSnackBarConfig } from '@angular/material/snack-bar';

const DURATION = 7000;

export class SnackbarConfig {
  static success: MatSnackBarConfig = {
    duration: DURATION,
    panelClass: ['snackbar', 'success']
  };

  static error: MatSnackBarConfig = {
    duration: DURATION,
    panelClass: ['snackbar', 'error']
  };
}
