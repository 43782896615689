import { createReducer, on, Action } from '@ngrx/store';

import { User } from '@models/user/user';

import * as UserActions from './user-actions';

export const initialState: User = null;

const userReducer = createReducer(
  initialState,
  on(UserActions.update, (state, { user }) => updateUser(state, user))
);

function updateUser(state: User, user: User): User {
  return new User(user.id, user.lastname, user.firstname, user.email, user.roles, user.acronym, user.color);
}

export function reducer(state: User | undefined, action: Action): User {
  return userReducer(state, action);
}
