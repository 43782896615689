import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SubheaderContentPlaceholderComponent } from './subheader-content-placeholder.component';
import { SubheaderComponent } from './subheader.component';

@NgModule({
  imports: [CommonModule],
  declarations: [SubheaderComponent, SubheaderContentPlaceholderComponent],
  exports: [SubheaderComponent, SubheaderContentPlaceholderComponent]
})
export class TiimeSubheaderModule {}
