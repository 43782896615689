import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';

export class SearchBarForm extends UntypedFormGroup {
  get search(): UntypedFormControl {
    return this.get('search') as UntypedFormControl;
  }

  constructor() {
    super({
      search: new UntypedFormControl(null)
    });
  }
}
