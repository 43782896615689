import { Injectable } from '@angular/core';

import { select, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, take, tap } from 'rxjs/operators';

import { UserApiService } from '@api-services/user-api.service';
import { User } from '@models/user/user';
import * as UserActions from '@store/user/user-actions';
import { userSelector } from '@store/user/user-selector';

@Injectable({
  providedIn: 'root'
})
export class UserGuard {
  constructor(private store: Store, private userApiService: UserApiService) {}

  canLoad(): Observable<boolean> {
    return this.hasUser();
  }

  private hasUserInStore(): Observable<boolean> {
    return this.store.pipe(
      select(userSelector),
      map((user: User) => !!user),
      take(1)
    );
  }

  private hasUserInApi(): Observable<boolean> {
    return this.userApiService.getUserMe().pipe(
      tap((user: User) => this.store.dispatch(UserActions.update({ user }))),
      catchError(() => of(false)),
      switchMap(() => this.hasUserInStore())
    );
  }

  private hasUser(): Observable<boolean> {
    return this.hasUserInStore().pipe(switchMap((inStore: boolean) => (inStore ? of(inStore) : this.hasUserInApi())));
  }
}
