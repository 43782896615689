<div class="mat-calendar-header">
  <div class="mat-calendar-controls">
    <div
      role="button"
      class="mat-calendar-period-button"
      (click)="currentPeriodClicked()"
    >
      {{ periodButtonText }}
      <mat-icon
        inline="true"
        class="tiime-mat-calendar-arrow"
        fontIcon="icon-ic-arrow-bottom"
        [class.mat-calendar-invert]="calendar.currentView !== 'month'"
      ></mat-icon>
    </div>

    <div class="mat-calendar-spacer"></div>

    <mat-icon
      inline="true"
      role="button"
      class="tiime-mat-calendar-previous-button"
      [ngClass]="{disabled: !previousEnabled()}"
      (click)="previousClicked()"
      fontIcon="icon-chevron-left"
    ></mat-icon>

    <mat-icon
      inline="true"
      role="button"
      class="tiime-mat-calendar-next-button"
      [ngClass]="{disabled: !nextEnabled()}"
      (click)="nextClicked()"
      fontIcon="icon-chevron-right"
    ></mat-icon>
  </div>
</div>
