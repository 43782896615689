<span
  tiimeTooltip
  [tooltipContent]="tagTooltipTemplate"
  tooltipHideWithoutEllipsis
>
  {{ tag }}
</span>
<ng-template #tagTooltipTemplate>
  <div class="template-tooltip">
    {{ tag }}
  </div>
</ng-template>
