import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'tiime-secondary-filters',
  templateUrl: './secondary-filters.component.html',
  styleUrls: ['./secondary-filters.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SecondaryFiltersComponent {
  @Input() badge: number;
  @Input() badgeHidden: boolean;

  panelOpen = false;
  offsetY = 5;

  constructor(private cdr: ChangeDetectorRef) {}

  closePanel(): void {
    this.panelOpen = false;
    this.cdr.markForCheck();
  }

  openPanel(): void {
    this.panelOpen = true;
    this.cdr.markForCheck();
  }
}
