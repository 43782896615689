<tiime-container class="tiime-sticky-table-container" [containerState]="containerState">
  <ng-content tiimeContainerHeader select="[tiimeContainerHeader]"></ng-content>
  <ng-content
    tiimeContainerHeaderLeft
    select="[tiimeContainerHeaderLeft]"
  ></ng-content>
  <ng-content
    tiimeContainerHeaderRight
    select="[tiimeContainerHeaderRight]"
  ></ng-content>
  <ng-content
    tiimeContainerContent
    select="[tiimeContainerContent]"
  ></ng-content>
  <ng-content
    tiimeContainerSideContent
    select="[tiimeContainerSideContent]"
  ></ng-content>
  <ng-content tiimeContainerFooter select="[tiimeContainerFooter]"></ng-content>
  <ng-content
    tiimeContainerOnboard
    select="[tiimeContainerOnboard]"
  ></ng-content>
  <tiime-sticky-table-content-placeholder
    tiimeContainerContentPlaceholder
  ></tiime-sticky-table-content-placeholder>
  <tiime-sticky-table-no-results
    tiimeContainerNoResult
  ></tiime-sticky-table-no-results>
</tiime-container>
