import { Component, ViewEncapsulation, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';

@Component({
  selector: 'tiime-snackbar',
  templateUrl: './snackbar.component.html',
  styleUrls: ['./snackbar.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SnackbarComponent {
  constructor(
    public snackBarRef: MatSnackBarRef<SnackbarComponent>,
    @Inject(MAT_SNACK_BAR_DATA) public message: string
  ) {}

  close(): void {
    this.snackBarRef.dismiss();
  }
}
