import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  ViewEncapsulation
} from '@angular/core';

@Component({
  selector: 'tiime-sticky-table-no-results',
  templateUrl: './sticky-table-no-results.component.html',
  styleUrls: ['./sticky-table-no-results.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StickyTableNoResultsComponent {
  @HostBinding('class') class = 'tiime-sticky-table-no-results';

  constructor() {}
}
