import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDatepickerModule } from '@angular/material/datepicker';

import { DatepickerHeaderComponent } from './datepicker-header.component';
import { TiimeIconModule } from '../icon/index';

@NgModule({
    declarations: [DatepickerHeaderComponent],
    imports: [CommonModule, MatDatepickerModule, TiimeIconModule],
    exports: [DatepickerHeaderComponent, MatDatepickerModule]
})
export class TiimeDatepickerModule {}
