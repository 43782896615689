import { HttpParameterCodec } from '@angular/common/http';

/*
 * We need to use this encoder for HttpParams until this issue
 * is resolved : https://github.com/angular/angular/issues/11058.
 *
 * Otherwise some characters are not properly encoded : @:$,;+=?/
 */
export class TemporaryEncoder implements HttpParameterCodec {
  encodeKey(key: string): string {
    return encodeURIComponent(key);
  }

  encodeValue(value: string): string {
    return encodeURIComponent(value);
  }

  decodeKey(key: string): string {
    return decodeURIComponent(key);
  }

  decodeValue(value: string): string {
    return decodeURIComponent(value);
  }
}
