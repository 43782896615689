import { CommonModule } from '@angular/common';
import { NgModule, Injector } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { ClipboardModule } from 'ngx-clipboard';

import { TiimePipesModule } from 'tiime-utils';

import { MaterialModule } from './material/material.module';
import { setSharedInjector } from './shared-injector';
@NgModule({
  imports: [CommonModule, FormsModule, RouterModule, ReactiveFormsModule, MaterialModule, TiimePipesModule],
  declarations: [],
  exports: [
    CommonModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    MaterialModule,
    TiimePipesModule,
    ClipboardModule
  ]
})
export class SharedModule {
  constructor(injector: Injector) {
    setSharedInjector(injector);
  }
}
