import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'tiime-resize-grabber',
  templateUrl: './resize-grabber.component.html',
  styleUrls: ['./resize-grabber.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'tiime-resize-grabber'
  }
})
export class ResizeGrabberComponent {
  @Output() readonly dragStarted: EventEmitter<void> = new EventEmitter<void>();
  @Output() readonly dragMoved: EventEmitter<number> = new EventEmitter<number>();
  @Output() readonly dragEnded: EventEmitter<void> = new EventEmitter<void>();

  handleDragStarted(): void {
    this.dragStarted.emit();
  }

  handleDragMoved(move: number): void {
    this.dragMoved.emit(move);
  }

  handleDragEnded(): void {
    this.dragEnded.emit();
  }
}
