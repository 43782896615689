import {
  Component,
  ViewEncapsulation,
  ChangeDetectionStrategy,
  ElementRef,
  Input
} from '@angular/core';

import { HasAttributesBase } from '../core/index';

@Component({
  selector: 'tiime-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CardComponent extends HasAttributesBase {
  @Input() color: string;

  get cardClass(): Record<string, any> {
    return {
      primary: this.color === 'primary',
      warn: this.color === 'warn',
      accent: this.color === 'accent',
      raised: this._hasHostAttributes('raised')
    };
  }

  constructor(elementRef: ElementRef) {
    super(elementRef);
  }
}
