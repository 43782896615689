import { TiimeOverlayRef } from './overlay.ref';

export abstract class OverlayBase<Result = any> {
  isLoading = false;

  constructor(protected overlayRef: TiimeOverlayRef) {}

  close(value: Result): void {
    this.overlayRef.close(value);
  }

  cancel(): void {
    this.overlayRef.close();
  }
}
