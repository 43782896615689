import { NgModule } from '@angular/core';
import { MatMenuModule, MAT_MENU_DEFAULT_OPTIONS, MatMenuDefaultOptions } from '@angular/material/menu';

const menuDefaultOptions: MatMenuDefaultOptions = {
  backdropClass: '',
  hasBackdrop: true,
  overlapTrigger: true,
  yPosition: 'below',
  xPosition: 'after'
};

@NgModule({
  imports: [MatMenuModule],
  exports: [MatMenuModule],
  providers: [
    { provide: MAT_MENU_DEFAULT_OPTIONS, useValue: menuDefaultOptions }
  ]
})
export class TiimeMenuModule {}
