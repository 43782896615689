import { ChangeDetectionStrategy, Component, ElementRef, Input, ViewChild } from '@angular/core';

import { TiimeOverlayService } from '../overlay';
import { ColumnsSelectorComponent, ColumnsSelectorData } from './columns-selector.component';

@Component({
  selector: 'tiime-columns-selector-button',
  templateUrl: './columns-selector-button.component.html',
  styleUrls: ['./columns-selector-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ColumnsSelectorButtonComponent {
  @Input() minSelected = 1;

  @ViewChild('columnsSelector') columnsSelectorRef: ElementRef;

  constructor(private overlayService: TiimeOverlayService) {}

  openColumnsSelectorOverlay(): void {
    const data: ColumnsSelectorData = { minSelected: this.minSelected };
    this.overlayService
      .open(ColumnsSelectorComponent, data, {
        backdropClass: 'cdk-overlay-transparent-backdrop',
        height: 'auto',
        width: 'auto',
        hasBackdrop: true,
        backdropClose: true,
        connectTo: {
          origin: this.columnsSelectorRef,
          positions: [
            {
              originX: 'start',
              originY: 'bottom',
              overlayX: 'end',
              overlayY: 'top',
              offsetX: -5,
              offsetY: 10
            }
          ]
        }
      })
      .afterClosed()
      .subscribe();
  }
}
